import styled from "styled-components";

import { CommonPageWrapper, CommonPage } from "@styles/index.style";

export const PageWrapper = styled(CommonPageWrapper)``;

export const Page = styled(CommonPage)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
